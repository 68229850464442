import React from "react"
import { showreels, reelsWrap, reelWrap, reelContent, reelVideo } from "../styles/components/Showreels.module.css"

const Showreels = ({ className, reels }) => {
  return (
    <div className={`${className} ${showreels}`}>
      <h2>Showreels</h2>
      <div className={reelsWrap}>
        {reels.map((reel) => {
          const copy = reel.copy?.trim()

          return (
            reel.oembed && (
              <div className={reelWrap} key={reel.oembed}>
                {copy && <div className={reelContent} dangerouslySetInnerHTML={{ __html: copy }}></div>}
                <div className={reelVideo} dangerouslySetInnerHTML={{ __html: reel.oembed }}></div>
              </div>
            )
          )
        })}
      </div>
    </div>
  )
}

export default Showreels
