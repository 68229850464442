import React from "react"
import { graphql } from "gatsby"
import Carousel from "../components/Carousel"
import Layout from "../components/Layout"
import Showreels from "../components/Showreels"
import HTMLHead from "../components/HTMLHead"
import * as talentStyles from "../styles/components/Talent.module.css"

const Talent = ({
  data: {
    wpTalent: {
      title,
      talentFields: { carouselImages, description, links, download, showreels },
    },
  },
}) => {
  return (
    <Layout fadeInDelay={1} key={title}>
      <h1 className={talentStyles.title}>{title}</h1>
      {carouselImages && <Carousel images={carouselImages} />}

      <div className={talentStyles.description}>
        <div className={talentStyles.descriptionInner}>
          {description && <div className={talentStyles.descriptionCopy} dangerouslySetInnerHTML={{ __html: description }}></div>}
          {links && (
            <div className={talentStyles.links}>
              {links.map(
                (link) =>
                  link.text &&
                  link.url && (
                    <a key={link.url} className={talentStyles.link} href={link.url} target="_blank" rel="noreferrer">
                      {link.text}
                    </a>
                  )
              )}
            </div>
          )}
        </div>
        {download?.localFile.publicURL && (
          <a className={talentStyles.download} href={download?.localFile.publicURL} target="_blank" rel="noreferrer" download>
            Download c.v.
          </a>
        )}
      </div>

      {showreels && <Showreels reels={showreels} className={talentStyles.showreelsWrap} />}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpTalent(slug: { eq: $slug }) {
      title
      talentFields {
        carouselImages: carousel {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 506, quality: 80)
            }
          }
          sourceUrl
          width
          height
        }
        description
        links {
          text
          url
        }
        download {
          localFile {
            publicURL
          }
        }
        showreels {
          oembed
          copy
        }
      }
    }
  }
`

export default Talent

export const Head = ({
  data: {
    wpTalent: {
      title,
      talentFields: { carouselImages },
    },
  },
}) => {
  const firstImageUrl = carouselImages[0]?.sourceUrl
  const firstImageWidth = carouselImages[0]?.width
  const firstImageHeight = carouselImages[0]?.height
  return <HTMLHead pageTitle={title} pageImageUrl={firstImageUrl} pageImageWidth={firstImageWidth} pageImageHeight={firstImageHeight} />
}
