import React, { useEffect, useState } from "react"
import { useQuery, gql } from "@apollo/client"
import Talent from "../../templates/talent"
import Layout from "../../components/Layout"

const PREVIEW_QUERY = gql`
  query Preview($id: ID!) {
    talent(id: $id, idType: DATABASE_ID) {
      title
      revisions {
        nodes {
          isPreview
          title
          talentFields {
            carouselImages: carousel {
              altText
              sourceUrl
              mediaDetails {
                width
                height
              }
            }
            description
            links {
              text
              url
            }
            download {
              mediaItemUrl
              localFile @client
            }
            showreels {
              oembed
              copy
            }
          }
        }
      }
    }
  }
`

const PreviewPage = ({ location }) => {
  const [preview, setPreview] = useState()

  const params = new URLSearchParams(location.search)
  const { data, loading, error } = useQuery(PREVIEW_QUERY, {
    variables: {
      id: params.get("id"),
      nonce: params.get("nonce"),
    },
  })

  useEffect(() => {
    if (data) {
      setPreview(data.talent.revisions.nodes.find((node) => node.isPreview))
    }
  }, [data])

  if (loading) {
    return (
      <Layout key="talentPreviewLoading">
        <p>Loading preview...</p>
      </Layout>
    )
  } else if (error) {
    console.error(error.name, error.message)

    return (
      <Layout key="talentPreviewError">
        <p>There was an error loading the requested preview.</p>
      </Layout>
    )
  } else if (preview) {
    return (
      <>
        <Talent data={{ wpTalent: preview }} />
      </>
    )
  }
}

export default PreviewPage
